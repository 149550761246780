import GetInTouch from "components/getInTouch";
import Reviews from "components/homepage/reviews";
import TranslateAgency from "components/homepage/translateAgency";
import TranslationServices from "components/homepage/translationServices";
import WhatChooseUs from "components/homepage/whyChooseUs";
import LocationBanner from "components/locationBanner";
import React from "react";
import Content from "./content";

export default function NotarizedTranslation() {
  return (
    <div>
      <LocationBanner
        backgroundCol="bg-midBlue"
        title="Notarized Translation Services"
        content="Tomedes is experienced in notarized translation services and can notarize documents, translate and certify the accuracy of translated documents, certificates, and documentary evidence."
        formOrangeHead="Get in touch  "
        formWhiteHead=" with our translation company"
      />
      <TranslateAgency
        head="Trusted by Satisfied Clients Worldwide"
        colorHead="95,000+"
        coloR="text-orange"
        content="At Tomedes, the client is always our priority, and client happiness drives our business ethic. We have a satisfaction rate of 97% because the customer always comes first."
      />
      <Content />
      <WhatChooseUs backgroundCol="bg-darkBlue" />
      <Reviews backgroundCol="bg-midBlue" />
      <GetInTouch />
    </div>
  );
}
