import React from "react";
import { Link } from "gatsby";

export default function GlobalFixContactus({ head, para, link, btnName }) {
  return (
    <div className="w-full bg-GlobalContact bg-cover bg-no-repeat mx-auto py-7 px-8 md:px-12 text-white min-h-[189px] flex justify-center">
      <div className=" flex flex-col lg:flex-row md:items-center justify-between sm:min-w-[75%] lg:max-w-6xl">
        <div className="text-[28px] w-full lg:w-[60%]">
          <h2 className="font-bold font-secondary"> {head ? head : "Contact Us 24/7"} </h2>
          <p> {para ? para : "We will respond within 15 minutes"}</p>
        </div>
        <div className="my-8 md:my-0 w-full pt-4 lg:pt-0 lg:w-[30%] flex justify-center sm:justify-end mx-auto">
          <Link
            to={link ? link : "/contactus.php"}
            className="font-opensans font-bold text-17 text-center uppercase rounded-full bg-[#000C27] text-white cursor-pointer  py-4 px-8 min-w-max hover:bg-[#0032A7]"
          >
            {btnName ? btnName : "Contact Us"}
          </Link>
        </div>
      </div>
    </div>
  );
}
