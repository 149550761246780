import React from "react";
import GlobalFixContactus from "../GlobalFixContactus";

export default function Content() {
  return (
    <div className="bg-midBlue text-[#E6E6E6]" id="notarized">
      <div className="content-container w-9/12 flex font-secondary flex-col justify-start mx-auto py-10">
        <p className="text-[18px] font-opensans py-[100px]">
          We deliver high quality, notarized translation services that meet the
          rigid requirements of any government agency or international
          organization. We work with the best translators to provide accurate
          document translations promptly anywhere in the world.
        </p>
        <h2 className="text-white text-[35px] pb-[40px] ">
          Notarized Translation Services - Explained
        </h2>
        <p className="font-opensans">
          If you haven’t used a staffing company before, now is the time to
          start. Staffing agencies exist to manage your recruitment processes
          for you. A top recruiting company can save you an immense amount of
          time, effort and hassle, leaving you free to focus on your core
          operations.
        </p>
        <h2 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Notarized Translation{" "}
        </h2>
        <p className="font-opensans">
          Notarization means that a notary public has been involved in the
          preparation of a document. Most commonly, a notary attests to the
          manifest evidence that a document has been signed in a particular
          place and time. The notary public may also be authorized by a given
          jurisdiction to witness and certify that various formalities are in
          accordance with prescribed procedures, including production of a
          notarized translation of educational transcripts, diplomas,
          professional certificates and other vital records issued in a foreign
          language.
        </p>
        <h2 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Notarized vs Certified Translation
        </h2>
        <p className="font-opensans">
          A certified translation fulfills the requirements of certification in
          a given jurisdiction, approved as accurate by an accredited
          translation agency, allowing the certified document to be valid for
          use in formal proceedings. A translator accredited by that
          jurisdiction undertakes responsibility for the document translation’s
          accuracy. Accreditation requirements and parameters vary from locality
          to locality. So it is essential that the translation agency with whom
          you work is accredited in that jurisdiction. Not every certified
          translation must be notarized.
        </p>
        <h2 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Use Cases
        </h2>
        <p className="font-opensans">
          Notarized translations are required in a broad range of situations
          where the authenticity and accuracy of a document’s translation is
          deemed important. The global Tomedes translation team is experienced
          in diverse use cases.
        </p>
        <h3 className="text-[26px] pt-[100px]">
          Commonly Notarized Translated Documents
        </h3>
        <h4 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Legal Documents
        </h4>
        <p className="font-opensans">
          Notarized translations are often required by legal authorities as
          another means of validating that a document issued in a foreign
          language is in fact translated completely and correctly. The documents
          in question range from vital records like birth and marriage
          certificates, to business contracts. The Tomedes worldwide team
          includes linguists with legal training.
        </p>
        <a
          href="http://tomedes.com/legal-translation.php"
          className="text[20px] font-secondary hover:text-orange underline pt-2"
        >
          LEARN MORE
        </a>
        <h4 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Financial Documents
        </h4>
        <p className="font-opensans">
          Foreign-issued financial documents often require a notarized
          translation attesting to a notary public’s confirmation that the
          document was translated in accordance with the prescribed procedures
          for such documents in that jurisdiction. These can include
          translations of tax returns, declarations of assets, and other
          financial and professional documents.
        </p>
        <a
          href="http://tomedes.com/financial-translation.php"
          className="text[20px] font-secondary hover:text-orange underline pt-2"
        >
          LEARN MORE
        </a>
        <h4 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Personal documents (medical documents, sworn statements etc.){" "}
        </h4>
        <p className="font-opensans">
          A notarized translation is recognized by a given jurisdiction
          confirming the authenticity of how a document or document-related
          event was translated. Documents which might be subject to notarization
          include medical records, sworn statements and records of birth,
          marriage and death as well as educational diplomas, transcripts, and
          professional certifications.
        </p>
        <h3 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Our Recent Notarized Translation Projects
        </h3>
        <p className="font-opensans">
          Following are just a few samples from the steady stream of translation
          notarization assignments that Tomedes receives and completes
          successfully. Our global team of linguists, comprising thousands of
          translators expert in more than 1,000+ language pairs, is trained and
          certified to execute notarized translations in thousands of
          jurisdictions around the world.
        </p>
        <ul className="list-disc list-inside pb-[40px] pt-[20px]">
          <li>
            <a
              href="https://www.tomedes.com/recent-translations/spanish-notarized-document-translation.php"
              target="_blank"
              className="hover:text-orange"
            >
              Spanish Notarized Document Translation
            </a>
          </li>
          <li>
            <a
              href="https://www.tomedes.com/recent-translations/english-to-armenian-notarized-document-translation.php"
              target="_blank"
              className="hover:text-orange"
            >
              English to Armenian Notarized Document Translation
            </a>
          </li>
          <li>
            <a
              href=" https://www.tomedes.com/recent-translations/notarized-indonesian-translation-of-birth-certificate.php"
              className="hover:text-orange"
            >
              Notarized Birth Certificate Translation from Indonesian to English
            </a>
          </li>
          <li>
            <a
              href="https://www.tomedes.com/recent-translations/notarized-english-to-ukrainian-translation.php"
              target="_blank"
              className="hover:text-orange"
            >
              Notarized English to Ukrainian Translation
            </a>
          </li>
        </ul>
        <GlobalFixContactus
          head="Looking for Notarized Translation Services?"
          para="Tomedes specializes in preparation of notarized translations. Please
          send us your scanned documents and tell us what you require. We’ll
          respond immediately."
        />

        <h2 className="text-white text-[35px] pb-[40px] pt-[100px]">
          FAQs on Notarized Translations{" "}
        </h2>
        <p className="font-opensans">
          Tomedes specializes in preparation of notarized translations. Please
          send us your scanned documents and tell us what you require. We’ll
          respond immediately.
        </p>
        <h4 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Do translated documents need to be notarized?{" "}
        </h4>
        <p className="font-opensans">
          Not all translated documents require certification or notarization,
          but many jurisdictions require this to ensure the authenticity of the
          document translation or related signatures. Better to be safe than
          sorry.{" "}
        </p>
        <h4 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Should my notarized translation be accompanied by a certification?{" "}
        </h4>
        <p className="font-opensans">
          Always carefully follow instructions of the jurisdiction or authority
          requesting notarized or certified translations. It is advisable to
          send these instructions to Tomedes so that we can verify their
          implementation.{" "}
        </p>
        <h4 className="text-white text-[35px] pb-[40px] pt-[100px]">
          How can I find a notarized translator near me?{" "}
        </h4>
        <p className="font-opensans">
          These days, notarization and translation are performed online, so
          location is less important. Tomedes has a global presence, ensuring
          you get a translator fluent in your mother tongue and the required
          language.{" "}
        </p>
        <h2 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Notarized Translations in any Language{" "}
        </h2>
        <p className="font-opensans">
          The Tomedes translation team includes thousands of trained linguists
          from more than a hundred countries. Our expert network includes
          translation specialists trained and accredited to translate and
          interpret between more than 1,000+ language pairs, including the
          ability to notarize and certify translations. Naturally, the most
          common language for global business -- English, Spanish, Arabic,
          Chinese, German French, Japanese -- are well covered. But we also have
          expert translators on call for regional languages and dialects of
          Africa, Asia, Africa, and Europe.{" "}
        </p>
      </div>
    </div>
  );
}
