import Layout from "components/layout";
import NotarizedTranslation from "components/reusableComponents/notarizedTrans/NotarizedTranslation";
import SEO from "components/seo";
import React from "react";

export default function index() {
  const title = "Notarized Translation Services | Tomedes";
  const description = "Tomedes is experienced in notarized translation services and can notarize documents, translate and certify the accuracy of translated documents, certificates, and documentary evidence.";
  const keywords = "notarized translation, notarized translation service, professional notarized translation";
  return (
    <Layout>
    <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/notarized-translation.php"
      />
      <NotarizedTranslation />
    </Layout>
  );
}
